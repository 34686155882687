<template>
  <div class="evo-contact-us-wrapper">
    <template v-if="!$vuetify.breakpoint.mobile">
      <EvoContactUsSectionOne ref="contactUs1" />
      <EvoAboutUsSectionFour :style="{ 'padding-top': formHeight }" />
    </template>
    <template v-else>
      <EvoMContactUsSectionOne />
      <EvoMAboutUsSectionFour />
    </template>
  </div>
</template>

<script>
import EvoContactUsSectionOne from '@/components/contact_us_sections/Section_1.vue';
import EvoAboutUsSectionFour from '@/components/about_us_sections/Section_4.vue';
import EvoMContactUsSectionOne from '@/mobile/components/contact_us_sections/MSection_1.vue';
import EvoMAboutUsSectionFour from '@/mobile/components/about_us_sections/MSection_4.vue';
export default {
  name: 'EvoContactUs',
  data() {
    return {
      formHeight: '300px'
    };
  },
  components: {
    EvoContactUsSectionOne,
    EvoAboutUsSectionFour,
    EvoMContactUsSectionOne,
    EvoMAboutUsSectionFour
  },
  mounted() {
    if (!this.$vuetify.breakpoint.mobile) {
      const rowHeight = this.$refs.contactUs1.$refs.row.clientHeight;
      this.formHeight = `calc(${rowHeight}px - 90vh)`;
    }
  }
};
</script>

<style lang="scss" scoped>
.evo-contact-us-wrapper {
  background: $header_bg_color;
}
</style>
