<template>
  <div
    class="section-1 section-1-bg evo-ph-176 evo-pv-60 pb-0"
    :class="{
      'evo-pv-100': $vuetify.breakpoint.xsOnly,
      'evo-ph-80': $vuetify.breakpoint.xsOnly,
    }"
  >
    <v-row
      ref="row"
      class="px-0 evo-section1-row1"
      align="center"
      justify="center"
    >
      <v-col cols="12" sm="12" md="12">
        <div
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="1000"
          class="evo-section1-title evo-text-51 "
          :class="{ 'evo-px-text-51': $vuetify.breakpoint.xsOnly }"
        >
          {{ $t("contact_us") }}
        </div>
        <div
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
          class="evo-section1-subtitle evo-text-21 evo-opensans-regular "
          :class="{ 'evo-px-text-21': $vuetify.breakpoint.xsOnly }"
        >
          {{ $t("contact.get_in_touch_with_us_whenever_you_need_help") }}
        </div>
        <div
          data-aos="fade-up"
          data-aos-delay="150"
          data-aos-duration="1000"
          class="evo-right d-flex flex-column rounded-xl "
        >
          <v-form class="evo-form" ref="form" v-model="form.valid">
            <template v-for="(field, index) in form.fields">
              <div
                :key="'evo-label-' + index"
                class="evo-label evo-text-9"
                :class="{ 'evo-px-text-14': $vuetify.breakpoint.xsOnly }"
              >
                {{ $t(field.label) }}
              </div>
              <v-text-field
                v-if="!field.type"
                :key="'evo-text-field-' + index"
                v-model="field.value"
                :rules="field.rules"
                :label="field.placeholder"
                background-color="#f2f6fb"
                dense
                flat
                solo
                required
              >
              </v-text-field>

              <v-textarea
                v-if="field.type === 'textarea'"
                :key="'evo-textarea-' + index"
                v-model="field.value"
                :rules="field.rules"
                :label="field.placeholder"
                background-color="#f2f6fb"
                dense
                flat
                solo
                required
                rows="3"
                class="evo-textarea-wrapper"
              ></v-textarea>

              <v-select
                v-if="field.type === 'select'"
                :key="'evo-select-' + index"
                v-model="field.value"
                :items="field.items"
                :rules="field.rules"
                :label="field.placeholder"
                background-color="#f2f6fb"
                dense
                flat
                solo
                required
              ></v-select>
            </template>
            <v-btn
              color="primary"
              class="text-none evo-contact-us"
              @click="onContactUs"
            >
              {{ $t("contact_us") }}
            </v-btn>
          </v-form>
        </div>
      </v-col>
    </v-row>

    <v-overlay :value="overlay" opacity="0">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { contactUsSections1Mixin } from '@/mixins/contact-us.js';
export default {
  name: "EvoContactUsSectionOne",
  mixins: [contactUsSections1Mixin]
};
</script>

<style lang="scss" scoped>
.section-1 {
  position: relative;
  color: #ffffff;
  background-color: $nav_color;
  .evo-section1-title {
    height: 68px;
    line-height: 68px;
    text-align: center;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 23px;
  }

  .evo-section1-subtitle {
    text-align: center;
    color: #ffffff;
    line-height: 25px;
    font-weight: normal;
    margin-bottom: 60px;
  }

  .evo-section1-row1 {
    width: 479px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    margin-bottom: -224px;
    z-index: 1;
  }

  .evo-right {
    background: #ffffff;
    box-shadow: 0px 12px 36px 0px rgba(0, 39, 122, 0.18);
    width: 100%;
    height: 100%;
    padding: 24px 24px;

    .evo-form {
      .evo-label {
        font-weight: bold;
        color: #0c0c0c;
        margin-bottom: 3px;
      }

      .evo-contact-us {
        width: 130px;
        letter-spacing: normal;
        line-height: 12px;
        float: right;
      }
    }
  }

  .evo-trapezoid-img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 0;
  }

  .evo-section1-ellipse {
    position: absolute;
    z-index: 2;
    left: 188px;
    top: 220px;
  }
}

::v-deep(.evo-textarea-wrapper) {
  background: transparent;

  & textarea {
    // height: 100%;
    max-height: 96px;
  }
}

::v-deep(.v-text-field__details) {
  margin-bottom: 2px !important;
}

::v-deep(.v-input) {
  font-family: "OpenSans-Regular" !important;
}
</style>
