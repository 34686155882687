import { contactUs } from '@/helpers/Apis.js';
export const contactUsSections1Mixin = {
  data: function () {
    return {
      overlay: false,
      items: [
        {
          key: 1,
          src: require('@/assets/contact-us/section1-1.png'),
          desc: 'contact.desc_section_1_1'
        },
        {
          key: 2,
          src: require('@/assets/contact-us/section1-2.png'),
          desc: 'contact.desc_section_1_2'
        },
        {
          key: 3,
          src: require('@/assets/contact-us/section1-3.png'),
          desc: 'contact.desc_section_1_3'
        }
      ],
      form: {
        valid: true,
        fields: [
          {
            name: 'firstName',
            label: 'contact.first_name',
            placeholder: '',
            value: '',
            rules: [v => !!v || this.transRequired('contact.first_name')]
          },
          {
            name: 'lastName',
            label: 'contact.last_name',
            placeholder: '',
            value: '',
            rules: [v => !!v || this.transRequired('contact.last_name')]
          },
          {
            name: 'companyName',
            label: 'contact.company_name',
            placeholder: '',
            value: '',
            rules: [v => !!v || this.transRequired('contact.company_name')]
          },
          {
            name: 'companyEmail',
            label: 'contact.company_email',
            placeholder: '',
            value: '',
            rules: [
              v => !!v || this.transRequired('contact.company_email'),
              v => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(v) || this.$t('contact.email_must_be_valid')
            ]
          },
          {
            name: 'phone',
            label: 'contact.phone',
            placeholder: '',
            value: '',
            rules: [v => !!v || this.transRequired('contact.phone')]
          },
          {
            name: 'subject',
            label: 'contact.select_a_subject',
            placeholder: '',
            value: '',
            type: 'select',
            items: [this.$t('contact.subject_1'), this.$t('contact.subject_2'), this.$t('contact.subject_3')],
            rules: [v => !!v || this.transRequired('contact.subject')]
          },
          {
            name: 'message',
            label: 'contact.message',
            placeholder: '',
            value: '',
            type: 'textarea',
            rules: [v => !!v || this.transRequired('contact.message')]
          }
        ]
      }
    };
  },
  methods: {
    onContactUs() {
      this.$refs.form.validate();

      if (this.form.valid) {
        var data = this.form.fields.reduce((acc, item) => {
          acc[item.name] = item.value;
          return acc;
        }, Object(null));

        this.overlay = true;
        contactUs(data).then(
          res => {
            console.log(res);
            this.overlay = false;
            this.$refs.form.reset();
          },
          err => {
            console.log(err);
            this.overlay = false;
            throw err;
          }
        );
      }
    },
    transRequired(name) {
      return this.$t('contact.required', { name: this.$t(name) });
    }
  }
};
