<template>
  <div class="section-1 section-1-bg evo-pv-60">
    <div class="evo-mobile mx-auto text-center d-flex flex-column align-center">
      <div class="evo-title evo-text-66">
        {{ $t('contact_us') }}
      </div>
      <div class="evo-subtitle evo-text-33">
        {{ $t('contact.get_in_touch_with_us_whenever_you_need_help') }}
      </div>

      <v-form class="evo-form text-left" ref="form" v-model="form.valid">
        <template v-for="(field, index) in form.fields">
          <div :key="`evo-label-${index}`" class="evo-label evo-text-26 evo-opensans-regular">
            {{ $t(field.label) }}
          </div>
          <v-text-field
            v-if="!field.type"
            :key="'evo-text-field-' + index"
            v-model="field.value"
            :rules="field.rules"
            :label="field.placeholder"
            background-color="#f2f6fb"
            dense
            flat
            solo
            required
          >
          </v-text-field>

          <v-textarea
            v-if="field.type === 'textarea'"
            :key="'evo-textarea-' + index"
            v-model="field.value"
            :rules="field.rules"
            :label="field.placeholder"
            background-color="#f2f6fb"
            dense
            flat
            solo
            required
            rows="1"
            auto-grow
            class="evo-textarea-wrapper"
          ></v-textarea>

          <v-select
            v-if="field.type === 'select'"
            :key="'evo-select-' + index"
            v-model="field.value"
            :items="field.items"
            :rules="field.rules"
            :label="field.placeholder"
            background-color="#f2f6fb"
            dense
            flat
            solo
            required
          ></v-select>
        </template>
        <v-btn color="primary" class="text-none evo-contact-us" @click="onContactUs">
          {{ $t('contact_us') }}
        </v-btn>
      </v-form>
    </div>

    <v-overlay :value="overlay" opacity="0">
      <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { contactUsSections1Mixin } from '@/mixins/contact-us.js';
export default {
  name: 'EvoMContactUsSectionOne',
  mixins: [contactUsSections1Mixin]
};
</script>

<style lang="scss" scoped>
::v-deep(.v-text-field__details) {
  padding-left: 0 !important;
  margin-bottom: 8px !important;
}
.v-text-field.v-text-field--solo.v-input--dense > ::v-deep(.v-input__control) {
  min-height: 64px;
}
.section-1 {
  position: relative;
  background-color: $nav_color;
  margin-bottom: 1140px;
  .evo-title {
    margin-bottom: 30px;
  }

  .evo-subtitle {
    max-width: 609px;
    min-height: 228px;
    line-height: 54px;
  }

  .evo-form {
    width: 684px;
    background: #ffffff;
    box-shadow: 0px 18px 54px 0px rgba(0, 39, 122, 0.18);
    border-radius: 25px;
    padding: 30px 48px;
    .evo-label {
      font-weight: bold;
      color: #0c0c0c;
      margin-bottom: 13px;
    }

    .evo-contact-us {
      width: 220px;
      height: 70px;
      background: #2a6cfe;
      border-radius: 8px;
      float: right;
    }

    .v-input {
      font-size: 26px;
      color: #0c0c0c;
      font-family: 'OpenSans-Regular';
    }
  }
}

.section-1-bg {
  height: 96vw;
  background-position: left top;
  background-size: 182% !important;
}
</style>
